// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCCq6UVRJ2O_Uz_NATSb9Dw1fz7vtc58js",
  authDomain: "yvomvnew1stu.firebaseapp.com",
  databaseURL: "https://yvomvnew1stu-default-rtdb.firebaseio.com",
  projectId: "yvomvnew1stu",
  storageBucket: "yvomvnew1stu.appspot.com",
  messagingSenderId: "1083372197063",
  appId: "1:1083372197063:web:04cc633e1841b2c05c9ae2",
  measurementId: "G-KGLWHVP8V9"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };